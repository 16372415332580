import { Fragment, useEffect, useState } from 'react'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import { router } from '@inertiajs/react'
import { Link } from '@inertiajs/react'
import NoteDialog from '@/Shared/NoteDialog'
import { Tooltip } from '@/Shared/Tooltip'
import Notice from '../Notice'

export default ({ contact, preview, onEdit, onOpenTags }) => {
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [note, setNote] = useState(null)
  const administrating = route().current().startsWith('admin')
  const primaryEmail = contact.emails?.find((e) => e.primary)
  const primaryPhone = contact.phone_numbers?.find((p) => p.primary)
  const mobilePhone = contact.phone_numbers?.find((p) => p.type === 'Mobile')

  useEffect(() => {
    if (note != null) {
      setNoteDialogOpen(true)
    }
  }, [note])

  const onEditSettings = () => {
    router.visit(route('admin.pros.show', { contact: contact.id, subpage: 'account' }))
  }

  const onInvitePRO = () => {
    if (contact.invitation) {
      router.post(route('pros.invitations.resend', contact.invitation.id))
    } else {
      router.post(route('pros.store'), {
        sender_contact_id: contact.id,
        sender_contact_type: 'Contact',
        first_name: contact.first_name,
        last_name: contact.last_name,
        email: contact.emails?.find((email) => email.primary)?.email,
      })
    }
  }

  const onStarredChanged = () => {
    let data = {
      starred: !contact.starred,
      _method: 'patch',
    }

    router.post(route('contacts.star', contact.id), data, {
      preserveState: false,
    })
  }

  const onDelete = () => {
    if (contact.associate) {
      router.delete(route('admin.associates.destroy', contact.associate.id), {
        preserveState: false,
        onSuccess: () => {
          setDeleting(false)
        },
      })
    } else {
      router.delete(route('contacts.destroy', contact.id), { preserveState: false })
    }
  }

  const onRestore = () => {
    if (contact.associate) {
      router.post(route('admin.associates.update', contact.associate.id), {
        deleted_at: null,
        _method: 'put',
      })
    } else {
      router.post(route('contacts.restore', contact.id), { _method: 'patch' }, { preserveState: false })
    }
  }

  return (
    <div className="relative bg-white py-3 pb-6">
      <div className="mb-3 px-3 pl-2">
        {!preview && (
          <div className="relative z-10 flex items-center justify-between">
            <div className="flex flex-1 items-center justify-between">
              <div className="ml-4">
                <Link
                  href={route().current().startsWith('admin') ? route('admin.associates.index') : route('contacts.index')}
                  className="inline-flex h-10 items-center rounded-md px-3 leading-none text-blue-500 outline-none transition-all duration-150 ease-in-out hover:bg-blue-100 hover:text-gray-800 focus:ring-2 focus:ring-blue-500 md:-ml-5"
                >
                  <i className="far fa-angle-left pr-3 text-xl"></i>
                  <span className="font-medium">{route().current().startsWith('admin') ? 'Associates' : 'Contacts'}</span>
                </Link>
              </div>

              <div className="flex items-center justify-end gap-0.5">
                {!contact.associate && (
                  <Tooltip label="Tags" placement="bottom">
                    <Button theme="icon" onClick={onOpenTags} disabled={contact.deleted_at}>
                      <i className="far fa-user-tag text-lg"></i>
                    </Button>
                  </Tooltip>
                )}

                {(!contact.associate || administrating || (!contact.deleted_at && !contact.associate.deleted_at)) && (
                  <Tooltip label="Edit" placement="bottom">
                    <Button theme="icon" onClick={onEdit} disabled={!administrating && contact.deleted_at}>
                      <span className="sr-only">Edit</span>
                      <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                    </Button>
                  </Tooltip>
                )}

                {administrating && contact.associate && (
                  <Tooltip label="Edit Settings" placement="bottom">
                    <Button theme="icon" onClick={() => onEditSettings()}>
                      <span className="sr-only">Edit Settings</span>
                      <i className="far fa-gear"></i>
                    </Button>
                  </Tooltip>
                )}

                {route().current() !== 'my-card' && (
                  <Dropdown id="actions-options" label="Actions" orientation="right" size="w-64">
                    {!contact.associate && (
                      <DropdownItem onClick={() => onStarredChanged()} disabled={contact.deleted_at}>
                        <i className="far fa-star mr-4"></i>
                        {contact.starred ? 'Remove from' : 'Add to'} Starred
                      </DropdownItem>
                    )}

                    {contact.following?.length == 0 && (
                      <DropdownItem disabled={contact.deleted_at} onClick={() => onInvitePRO()}>
                        <i className="far fa-envelope mr-4"></i>
                        {contact.invitation ? 'Resend PRO Invitation' : 'Invite to become a PRO'}
                      </DropdownItem>
                    )}

                    <DropdownItem href={route('contacts.download-vcard', contact.id)} disabled={contact.deleted_at}>
                      <i className="far fa-address-card mr-4"></i>
                      Download vCard
                    </DropdownItem>

                    {Boolean(contact.associate ? contact.associate.deleted_at : contact.deleted_at) ? (
                      <DropdownItem colorClasses="text-green-600 hover:text-green-700" onClick={() => onRestore()}>
                        <i className="fas fa-undo mr-4"></i>
                        Restore
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        colorClasses="text-red-500 hover:text-red-600"
                        onClick={() => (contact.associate ? setDeleting(true) : onDelete())}
                      >
                        <i className="fas fa-trash mr-4"></i>
                        {contact.associate ? 'Terminate' : 'Delete'}
                      </DropdownItem>
                    )}
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        )}

        {(contact.associate || preview) && (
          <div className="flex flex-col items-center justify-center">
            <div className="group relative flex cursor-pointer items-center px-4 py-2">
              <Avatar contact={contact} height="h-20" width="w-20" textSize="text-4xl" />
            </div>
          </div>
        )}

        <div className="relative">
          <div className="mt-3 text-center text-2xl">
            <span className={classNames('font-medium', { 'text-orange-500': contact.starred })}>
              {contact.first_name || contact.last_name ? contact.full_name : contact.company}
            </span>
          </div>

          {((contact.associate && contact.associate.title) || (!contact.associate && (contact.industry || contact.title))) && (
            <div className="text text-center leading-tight">
              <span className={contact.starred ? 'text-gray-800' : 'text-gray-600'}>
                {contact.associate && contact.associate.title}

                {!contact.associate && contact.industry + (contact.industry && contact.title && ' | ') + contact.title}
              </span>
            </div>
          )}

          {(Boolean(contact.associate ? contact.associate.deleted_at : contact.deleted_at) || contact.associate?.pal) && (
            <div className="mt-2 flex justify-center gap-1.5">
              {Boolean(contact.associate ? contact.associate.deleted_at : contact.deleted_at) && (
                <div className="inline-block whitespace-nowrap rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-red-600">
                  <span>{Boolean(contact.associate?.deleted_at) ? 'Terminated' : 'Deleted'}</span>
                </div>
              )}

              {contact.associate?.pal && (
                <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                  <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                    <span>PAL</span>
                  </span>
                </div>
              )}
            </div>
          )}

          {Boolean(contact.starred) && (
            <div className="pointer-events-none absolute inset-0 flex items-center justify-center opacity-20">
              <i className="fas fa-star text-8xl text-orange-500 opacity-75"></i>
            </div>
          )}
        </div>
      </div>

      {route().current() !== 'my-card' && (
        <div className="relative mx-auto flex max-w-md items-center justify-center px-8">
          <div className="-mx-2 flex flex-1 items-center justify-between px-6">
            <Dropdown id="call-options" orientation="left" icon size="w-56">
              <Dropdown.DropdownButton>
                {({ id, clickRef, open, setOpen }) => (
                  <div className={classNames('px-2 text-center', primaryPhone ? 'group cursor-pointer' : 'cursor-not-allowed opacity-30')}>
                    <button
                      id={id}
                      ref={clickRef}
                      type="button"
                      aria-expanded={open}
                      aria-haspopup="true"
                      className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500"
                      onClick={() => setOpen(!open)}
                      disabled={!primaryPhone}
                    >
                      <i className="fad fa-mobile-android text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
                    </button>
                    <div className="mt-1 text-sm text-gray-700 group-hover:text-blue-700">Call</div>
                  </div>
                )}
              </Dropdown.DropdownButton>

              {primaryPhone && (
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = `tel:${primaryPhone.phone}`
                  }}
                >
                  <div className="flex items-center">
                    {primaryPhone.type === 'Mobile' ? (
                      <i className="fas fa-mobile-alt mr-3 text-lg leading-none text-gray-500"></i>
                    ) : (
                      <i className="fas fa-phone mr-3 text-lg leading-none text-gray-500"></i>
                    )}
                    Call {primaryPhone.phone_formatted}
                  </div>
                </DropdownItem>
              )}
            </Dropdown>

            <a
              href={primaryEmail ? `mailto:${primaryEmail.email}` : null}
              className={classNames('px-2 text-center', primaryEmail ? 'group cursor-pointer' : 'cursor-not-allowed opacity-30')}
              disabled={!primaryEmail}
              target="_blank"
            >
              <div className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500">
                <i className="fad fa-envelope text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
              </div>
              <div className="mt-1 text-sm text-gray-700 group-hover:text-blue-700">Mail</div>
            </a>

            <a
              href={mobilePhone ? `sms:${mobilePhone.phone}` : null}
              className={classNames('px-2 text-center', mobilePhone ? 'group cursor-pointer' : 'cursor-not-allowed opacity-30')}
              disabled={!mobilePhone}
              target="_blank"
            >
              <div className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-100 bg-blue-100 outline-none transition-all duration-300 group-hover:border-blue-500">
                <i className="fad fa-comment-alt-dots text-xl leading-none text-blue-500 group-hover:text-blue-700"></i>
              </div>
              <div className="mt-1 text-sm text-gray-700 group-hover:text-blue-700">Text</div>
            </a>

            {!preview && (
              <Fragment>
                <div className="group cursor-pointer px-2 text-center">
                  <button
                    type="button"
                    className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-100 bg-blue-50 outline-none transition-all duration-300 group-hover:border-blue-500"
                    onClick={() => setNote({ type: 'Note' })}
                  >
                    <i className="fad fa-note-medical text-xl leading-none text-blue-500 group-hover:text-blue-500"></i>
                  </button>
                  <div className="mt-1 text-sm text-gray-700 group-hover:text-blue-500">Note</div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}

      {note && (
        <NoteDialog
          open={noteDialogOpen}
          id={contact.id}
          type="Contact"
          noteOnly={false}
          record={note}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setNote(null)
            }, 150)
          }}
        />
      )}

      {contact.associate && (
        <DeleteConfirmationDialog
          open={deleting}
          buttonText="Yes, continue"
          title="Termination Confirmation"
          size="sm:max-w-xl"
          onCanceled={() => setDeleting(false)}
          onApproved={() => onDelete()}
        >
          <div className="space-y-3">
            <p className="font-semibold">Are you sure you want to terminate this Associate?</p>
            <p>
              This action will revoke this Associate's access to BetterLivingPRO and de-list their website and any associated LSNs on
              LSNPros.com.
            </p>
            <Notice subHeading="Administrative access to this Associate and historical transaction data will still be available." />
          </div>
        </DeleteConfirmationDialog>
      )}
    </div>
  )
}
