import { Fragment } from 'react'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import DataTableHeader from './DataTableHeader'
import MainLayout from '@/Shared/Layouts/Main'
import Pagination from '@/Shared/Pagination'
import SearchBar from './SearchBar'
import classNames from 'classnames'
import Table from '@/Shared/Table/Index'

const Team = ({ filters, viewTransactions }) => {
  const { team } = usePage().props

  if (!team) {
    return <></>
  }

  return (
    <Table>
      <Table.Header>
        <tr>
          <th className="h-12 cursor-pointer rounded-t bg-gray-200 px-4 font-medium text-gray-800" colSpan="2">
            <div className="flex items-center gap-3 leading-none">
              <div className="flex w-6 items-center justify-center">
                <i className="far fa-users text-gray-500"></i>
              </div>

              <span>Supported Associates ({team.length})</span>
            </div>
          </th>
        </tr>
      </Table.Header>
      <Table.Body>
        {team.map((associateContact) => (
          <TableRow
            text={
              <span>
                {associateContact.full_name} ({associateContact.transaction_count})
              </span>
            }
            active={filters.associates.find((contact) => contact.id == associateContact.id)}
            onClick={() => viewTransactions(associateContact.id)}
            key={associateContact.id}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

const TableRow = ({ active, icon, text, onClick }) => (
  <tr className={classNames(active ? 'bg-blue-100 text-blue-500' : 'text-gray-700 hover:bg-gray-100')}>
    <td className="cursor-pointer px-4 py-3 font-medium" onClick={() => onClick()}>
      <div className="flex items-center gap-3">
        {icon && (
          <div className="flex w-6 items-center justify-center">
            <i className={classNames(icon, active ? 'text-blue-500' : 'text-gray-400')}></i>
          </div>
        )}
        <div>{text}</div>
      </div>
    </td>

    <td className="w-16 cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium sm:hidden" onClick={() => onClick()}>
      <div className="flex items-center justify-end">
        <i className="far fa-angle-right text-2xl leading-none text-gray-700"></i>
      </div>
    </td>
  </tr>
)

function TransactionsIndex() {
  const { team, transactions, transaction_count, filters } = usePage().props
  const { user } = usePage().props.auth
  const { data, paginator } = transactions

  const rowClicked = (id) => {
    router.visit(route('transactions.show', id))
  }

  const viewTransactions = (contact_id) => {
    const updatedFilters = { ...filters, associates: [{ id: contact_id }] }
    const query = encodeURIComponent(JSON.stringify(updatedFilters))

    router.get(
      route(route().current(), {
        filters: query,
      }),
      {},
      {
        preserveState: true,
      },
    )
  }

  return (
    <div className="sm:flex sm:flex-col sm:overflow-hidden">
      <div className="sm:py-auto flex flex-wrap items-stretch justify-between border-b border-gray-200 bg-white px-4 py-4 md:min-h-[68px] md:px-6">
        <SearchBar />
      </div>

      <div className="sm:flex sm:flex-col sm:overflow-hidden">
        <div className="sm:flex sm:h-full sm:gap-3">
          {team?.length > 0 && (
            <div className="hidden space-y-3 bg-gray-100 py-3 pr-2 sm:ml-4 sm:block sm:w-[23rem] sm:overflow-y-auto">
              <Table>
                <Table.Body>
                  <TableRow
                    icon="far fa-list-check text-lg"
                    text={`My Transactions (${transaction_count})`}
                    active={filters.associates.length == 0 || filters.associates.find((contact) => contact.id == user.contact_id)}
                    onClick={() => viewTransactions(user.contact_id)}
                  />
                </Table.Body>
              </Table>

              <Team filters={filters} viewTransactions={viewTransactions} />
            </div>
          )}

          <div
            className={classNames('w-full overflow-y-auto px-4 py-3 sm:flex sm:flex-col', {
              'sm:pl-0': team?.length > 0,
            })}
          >
            <div className="bg-white shadow sm:rounded">
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden border-b border-gray-200 shadow">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <DataTableHeader sortBy={filters.sortBy} direction={filters.direction} />
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {data.map((item) => {
                            const { id, name, property_state, associates, propertyTypes, stage, states, status, completed_at, paused_at } =
                              item

                            return (
                              <tr key={id}>
                                <td className="hidden whitespace-nowrap px-4 py-3 md:table-cell">
                                  <div className="flex shrink-0 items-center justify-center">
                                    {associates.map((associate, index) => (
                                      <Fragment key={index}>
                                        {associate.avatar && (
                                          <div
                                            className={classNames(
                                              'overflow-hidden rounded-full ring-1 ring-white',
                                              index == 0 ? 'h-10 w-10' : 'h-9 w-9',
                                              index > 0 ? '-ml-2' : '',
                                            )}
                                            style={{ zIndex: associates.length - index }}
                                          >
                                            <img className="object-contain" src={associate.avatar} alt="" aria-hidden="true" />
                                          </div>
                                        )}
                                        {!associate.avatar && (
                                          <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-gray-700">
                                            <div className="text-xl font-medium tracking-wide">{associate.initials}</div>
                                          </div>
                                        )}
                                      </Fragment>
                                    ))}
                                  </div>
                                </td>

                                <td className="cursor-pointer space-y-1 px-4 py-3 sm:pl-0 sm:pr-6" onClick={() => rowClicked(id)}>
                                  <div className="text-lg font-medium text-blue-500 sm:text-base">{name}</div>

                                  <div className="hidden flex-wrap items-center gap-1.5 lg:flex">
                                    {completed_at ? (
                                      <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                        <span>Completed</span>
                                      </div>
                                    ) : Boolean(paused_at) ? (
                                      <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                        <span>Paused</span>
                                      </div>
                                    ) : status === 'Pending' ? (
                                      <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                                        <span>Sale Pending</span>
                                      </div>
                                    ) : (
                                      ['Archived', 'Lost'].indexOf(status) >= 0 && (
                                        <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                                          <span>{status}</span>
                                        </div>
                                      )
                                    )}

                                    {['On-Hold'].indexOf(status) >= 0 && (
                                      <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                                        <span>{status}</span>
                                      </div>
                                    )}

                                    {['Compliance', 'Under Review'].indexOf(status) >= 0 && (
                                      <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                                        <span>{status}</span>
                                      </div>
                                    )}

                                    {['Closed'].indexOf(status) >= 0 && (
                                      <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                        <span>Closed</span>
                                      </div>
                                    )}

                                    {propertyTypes?.map((type, index) => (
                                      <span
                                        className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                                        key={index}
                                      >
                                        <span>{type.name}</span>
                                      </span>
                                    ))}

                                    {property_state ? (
                                      <span className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm uppercase leading-none text-gray-800">
                                        <span>{property_state}</span>
                                      </span>
                                    ) : (
                                      states?.map((state, index) => (
                                        <span
                                          className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                                          key={index}
                                        >
                                          <span>{state.state}</span>
                                        </span>
                                      ))
                                    )}

                                    {!completed_at && !Boolean(paused_at) && (
                                      <div className="flex items-center justify-start gap-1.5">
                                        <span className="inline-block rounded bg-blue-200/30 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-blue-700">
                                          <span className="whitespace-pre-line leading-none sm:whitespace-nowrap">{stage.name}</span>
                                        </span>
                                        <span className="inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                                          {stage.elapsed}
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  <div className="leading-normal xl:hidden">
                                    {item.closing_at && <div>Closing Date: {item.closing_at}</div>}

                                    {['created_at', 'updated_at', 'mortgage_commitment_date'].indexOf(filters.sortBy) >= 0 &&
                                      item[filters.sortBy] && (
                                        <div>
                                          {(() => {
                                            switch (filters.sortBy) {
                                              case 'created_at':
                                                return 'Date Created'
                                              case 'mortgage_commitment_date':
                                                return 'Mortgage Commitment Date'
                                              default:
                                                return 'Last Activity'
                                            }
                                          })()}
                                          : {item[filters.sortBy]}
                                        </div>
                                      )}

                                    {Boolean(paused_at) && (
                                      <div className="mt-1 inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                                        <span>Paused</span>
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td
                                  className="hidden cursor-pointer whitespace-nowrap px-4 py-3 text-left text-gray-800 sm:px-6 xl:table-cell"
                                  onClick={() => rowClicked(id)}
                                  width="150px"
                                >
                                  {['created_at', 'updated_at', 'closing_at', 'mortgage_commitment_date'].indexOf(filters.sortBy) >= 0 &&
                                  item[filters.sortBy] ? (
                                    item[filters.sortBy]
                                  ) : item.closing_at ? (
                                    item.closing_at
                                  ) : (
                                    <div className="text-center">
                                      <i className="fas fa-ban text-gray-300"></i>
                                    </div>
                                  )}
                                </td>

                                <td
                                  className="cursor-pointer whitespace-nowrap py-3 pr-6 text-right font-medium"
                                  onClick={() => rowClicked(id)}
                                >
                                  <div className="flex items-center justify-end">
                                    <i className="far fa-angle-right text-2xl leading-none text-gray-800"></i>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}

                          {data.length == 0 && (
                            <tr>
                              <td colSpan="8" className="px-4 py-12 sm:px-6">
                                <div className="flex flex-col text-center">
                                  <span className="text-2xl font-semibold text-red-600">No transactions match your current filters.</span>
                                  <span className="text-lg font-medium text-gray-800">
                                    Expecting to see results? Check your filters to make sure you're not excluding results.
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {data.length > 0 && <Pagination paginator={paginator} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
TransactionsIndex.layout = (page) => <MainLayout title="Transactions" children={page} />

export default TransactionsIndex
