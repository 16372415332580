import { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import Accordion from '@/Shared/Accordion'
import Alert from '@/Shared/Alert'
import AdditionalCompensation from './AdditionalCompensation'
import AmountPercentInput from '@/Shared/Forms/AmountPercentInput'
import Checkbox from '@/Shared/Forms/Checkbox'
import ClientRebate from './ClientRebate'
import CommissionsSummary from './CommissionsSummary'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Dialog from '@/Shared/Dialog/Index'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import Radio from '@/Shared/Forms/Radio'
import Referrals from './Referrals'
import Summary from './Summary'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'
import classNames from 'classnames'
import Reimbursements from './Reimbursements'
import ReimbursementsSummary from './ReimbursementsSummary'

export default forwardRef(({ clearErrors, data, errors, open, setData, setError, onClosed, onSubmit }, scrollRef) => {
  const reimbursementPaneRef = useRef(null)
  const reimbursementsRef = useRef(null)
  const focusRef = useRef()
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [subFormIsOpen, setSubFormOpen] = useState(false)
  const { constants, auth, transaction } = usePage().props
  const administrating = Helpers.isAdminOrHR(auth.user)
  const cooperatingParty = transaction.cooperating_party
  const minimumCapFee = Helpers.parseCurrency(constants.min_cap_fee)
  const primaryResidenceFee = Helpers.parseCurrency(constants.primary_residence_fee)
  const standardCommissionRate = Helpers.parseCurrency(constants.standard_commission_rate)
  const associates = transaction.contacts.filter((transactionContact) => transactionContact.contact_type === 'Associate')
  const owner = associates.find((contact) => contact.is_transaction_owner)
  const leadAssociate = owner.contact.associate
  const cooperatingAgents = transaction.contacts.filter((tc) =>
    tc.roles.find((r) => r.party_representing === cooperatingParty && r.name === 'Agent'),
  )
  const incomePayments = transaction.payments.filter((payment) => !payment.credit && !payment.archived)

  useEffect(() => {
    if (Object.entries(errors).length === 0 && transaction) {
      setData({
        ...data,
        closing_at: transaction.closing_at || null,
        closing_report_status: transaction.closing_report_status || 'draft',
        total_purchase_price:
          transaction.total_purchase_price != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.total_purchase_price), 2)
            : null,
        closing_cost:
          transaction.closing_cost != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.closing_cost), 2) : null,
        escrow_deposit1:
          transaction.escrow_deposit1 != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_deposit1), 2) : null,
        escrow_deposit2:
          transaction.escrow_deposit2 != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_deposit2), 2) : null,
        escrow_coop_broker:
          transaction.escrow_coop_broker != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_coop_broker), 2)
            : null,
        escrow_coop_broker_info: transaction.escrow_coop_broker_info || '',
        escrow_closing_attorney:
          transaction.escrow_closing_attorney != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_closing_attorney), 2)
            : null,
        escrow_closing_attorney_info: transaction.escrow_closing_attorney_info || '',
        escrow_buyer:
          transaction.escrow_buyer != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_buyer), 2) : null,
        escrow_buyer_info: transaction.escrow_buyer_info || '',
        escrow_seller:
          transaction.escrow_seller != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.escrow_seller), 2) : null,
        escrow_seller_info: transaction.escrow_seller_info || '',
        commission_basis: transaction.commission_basis || 'net',
        commission_additional:
          transaction.commission_additional != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.commission_additional), 2)
            : null,
        commission_additional_pct: transaction.commission_additional_pct ? parseFloat(transaction.commission_additional_pct) : null,
        commission_blre:
          transaction.commission_blre != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.commission_blre), 2) : null,
        commission_blre_pct: transaction.commission_blre_pct ? parseFloat(transaction.commission_blre_pct) : null,
        commission_cooperating:
          transaction.commission_cooperating != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.commission_cooperating), 2)
            : null,
        commission_cooperating_pct: transaction.commission_cooperating_pct ? parseFloat(transaction.commission_cooperating_pct) : null,
        primary_residence: transaction.primary_residence || false,
        personal_investment_property: transaction.personal_investment_property || false,
        use_standard_rate: transaction.use_standard_rate || false,
        referral_auto_distribute: transaction.referral_fee > 0 ? transaction.referral_auto_distribute : true,
        referral: transaction.referral || null,
        referral_type: transaction.referral_type || '',
        referral_id: transaction.referral_id || null,
        referral_fee: transaction.referral_fee || null,
        referral_fee_pct: transaction.referral_fee_pct || null,
        referral_name: transaction.referral_name || '',
        referral_info: transaction.referral_info || '',
        community_protector_rebate:
          transaction.community_protector_rebate != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.community_protector_rebate), 2)
            : null,
        community_protector_rebate_info: transaction.community_protector_rebate_info || '',
        mygivebackprogram_rebate:
          transaction.mygivebackprogram_rebate != undefined
            ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.mygivebackprogram_rebate), 2)
            : null,
        mygivebackprogram_rebate_info: transaction.mygivebackprogram_rebate_info || '',
        client_rebate:
          transaction.client_rebate != undefined ? Helpers.formatDecimal(Helpers.parseCurrency(transaction.client_rebate), 2) : null,
        client_rebate_auto_distribute: transaction.client_rebate > 0 ? transaction.client_rebate_auto_distribute : true,
        additional_compensation: transaction.additional_compensation.length > 0 ? transaction.additional_compensation : [{}],
        reimbursements: transaction.reimbursements?.length > 0 ? transaction.reimbursements : [],
        fee_description: transaction.fee_description,
        splits: recalculateAssociateCommission(),
      })
    }
  }, [transaction])

  useEffect(() => {
    if (loading) return

    setData((prevData) => ({
      ...prevData,
      ...{
        splits: recalculateAssociateCommission(),
      },
    }))
  }, [
    data.total_purchase_price,
    data.closing_cost,
    data.commission_basis,
    data.commission_additional,
    data.commission_blre,
    data.community_protector_rebate,
    data.mygivebackprogram_rebate,
    data.use_standard_rate,
  ])

  useEffect(() => {
    if (loading) {
      return
    }

    let validationChecks = {
      cooperating_agent_commission: isCooperatingAgentCommissionValid(),
      escrow: transaction.escrow_held_by_blre ? isEscrowValid() : true,
      escrow_payments: isEscrowDepositsValid(),
      client_rebate: isClientRebateValid(),
      rebates: isRebatesValid(),
      referral: isReferralValid(),
      splits: isSplitsApproved(),
    }

    if (Object.values(validationChecks).find((isValid) => isValid == false) != undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [data])

  useEffect(() => {
    setLoading(false)
  }, [data.closing_report_status])

  useEffect(() => {
    if (loading) return
    distributeClientRebateDeductions()
  }, [data.client_rebate, data.client_rebate_auto_distribute])

  // computed values
  const commissionFieldName = (type) => {
    return transaction.type.includes(type) ? 'commission_blre' : 'commission_cooperating'
  }

  const purchasePrice = () => {
    const dataSource = loading ? transaction : data
    return dataSource.commission_basis === 'net'
      ? Helpers.parseCurrency(dataSource.total_purchase_price) - Helpers.parseCurrency(dataSource.closing_cost)
      : Helpers.parseCurrency(dataSource.total_purchase_price)
  }

  const totalHeldInEscrow = () => {
    return Helpers.parseCurrency(data.escrow_deposit1) + Helpers.parseCurrency(data.escrow_deposit2)
  }

  const netRetained = () => {
    const commission_blre = totalBLRECommission()
    const referral_fee = Helpers.parseCurrency(
      loading
        ? transaction.referral_type === 'external' && transaction.referral_fee
          ? transaction.referral_fee
          : 0
        : data.referral_type === 'external' && data.referral_fee
          ? data.referral_fee
          : 0,
    )
    const community_protector_rebate = Helpers.parseCurrency(
      loading ? transaction.community_protector_rebate : data.community_protector_rebate,
    )
    const mygivebackprogram_rebate = Helpers.parseCurrency(loading ? transaction.mygivebackprogram_rebate : data.mygivebackprogram_rebate)

    return commission_blre - referral_fee - community_protector_rebate - mygivebackprogram_rebate
  }

  const totalDisbursements = () => {
    return (
      Helpers.parseCurrency(data.escrow_coop_broker) +
      Helpers.parseCurrency(data.escrow_closing_attorney) +
      Helpers.parseCurrency(data.escrow_buyer) +
      Helpers.parseCurrency(data.escrow_seller)
    )
  }

  const totalBLRECommission = () => {
    const dataSource = loading ? transaction : data
    const commission = Helpers.parseCurrency(dataSource.commission_blre)
    const commission_additional = dataSource.type === 'Buyer' ? Helpers.parseCurrency(transaction.commission_additional) : 0

    return commission + commission_additional
  }

  // functions
  const isCooperatingAgentCommissionValid = () => {
    // If there is an agent, check if their commission has been set
    if (data.closing_report_status === 'final' && !data.commission_cooperating && cooperatingAgents.length > 0) {
      setError(
        'cooperating_agent_commission_not_set',
        `The ${cooperatingParty}'s Agent's commission is required in order to change the Closing Report status to Final.`,
      )
      return false
    } else {
      clearErrors('cooperating_agent_commission_not_set')
      return true
    }
  }

  const isIncomeCommissionPaymentsPending = () => {
    const incomeCommissionPayments = incomePayments.filter((p) => p.type === 'Commission')
    return incomeCommissionPayments.length >= 0
  }

  const isEscrowValid = () => {
    let invalid = false,
      message = ''
    let blreCommission = totalBLRECommission()
    let availableSurplus = totalHeldInEscrow() - blreCommission

    if (availableSurplus > 0) {
      if (totalDisbursements() > availableSurplus) {
        invalid = true
        message = `The total additional escrow disbursements exceeds the available escrow surplus by $${Helpers.formatDecimal(
          totalDisbursements() - availableSurplus,
          2,
        )}.`

        setError({
          escrow_coop_broker: message,
          escrow_closing_attorney: message,
          escrow_buyer: message,
          escrow_seller: message,
        })
        return false
      }
    }

    ;['escrow_coop_broker', 'escrow_closing_attorney', 'escrow_buyer', 'escrow_seller'].map((group) => {
      if (Helpers.parseCurrency(data[group]) > 0) {
        if (data[`${group}_info`] === '') {
          invalid = true
          setError({
            ...errors,
            [group]: 'A mailing address is required when an amount is specified.',
            [`${group}_info`]: 'Please supply the mailing address where this disbursement check should be sent.',
          })
        }
      }
    })

    if (!invalid) {
      clearErrors(
        'escrow_coop_broker',
        'escrow_coop_broker_info',
        'escrow_closing_attorney',
        'escrow_closing_attorney_info',
        'escrow_buyer',
        'escrow_buyer_info',
        'escrow_seller',
        'escrow_seller_info',
      )
      return true
    }

    return false
  }

  const isEscrowDepositsValid = () => {
    let invalid = false,
      message = ''

    const escrowDeposit1 = Helpers.parseCurrency(data.escrow_deposit1)
    const escrowDeposit2 = Helpers.parseCurrency(data.escrow_deposit2)

    if (escrowDeposit2 > 0 && escrowDeposit1 == 0) {
      invalid = true
      message = `An initial escrow deposit is also required when adding a 2nd escrow deposit.`
    }

    if (invalid) {
      setError({
        escrow_deposit1: message,
      })
      return false
    } else {
      clearErrors('escrow_deposit1')
    }

    return true
  }

  const isClientRebateValid = () => {
    if (!data.splits) return true

    let clientRebateAmount = Math.round(Helpers.parseCurrency(data.client_rebate || 0) * 100) / 100
    let deductions = data.splits.reduce(
      (total, split) => Math.round((total + Helpers.parseCurrency(split.client_rebate_deduction || 0)) * 100) / 100,
      0,
    )

    if (clientRebateAmount - deductions != 0) {
      setError(
        'client_rebate_not_deducted',
        `The combined client rebate deduction amounts ($${Helpers.formatDecimal(deductions, 2)}) do not match the client rebate amount ($${Helpers.formatDecimal(clientRebateAmount, 2)}).`,
      )
      return false
    } else {
      clearErrors('client_rebate_not_deducted')
      return true
    }
  }

  const isRebatesValid = () => {
    if (Helpers.parseCurrency(data.community_protector_rebate) + Helpers.parseCurrency(data.mygivebackprogram_rebate) > 1000) {
      if (data.community_protector_rebate) setError('community_protector_rebate', 'The value of all rebates cannot exceed $1,000.')
      if (data.mygivebackprogram_rebate) setError('mygivebackprogram_rebate', 'The value of all rebates cannot exceed $1,000.')
      return false
    } else {
      clearErrors('community_protector_rebate', 'mygivebackprogram_rebate')
      return true
    }
  }

  const isReferralValid = () => {
    if (!data.splits || data.referral_type === 'external') return true

    let referralAmount = Math.round(Helpers.parseCurrency(data.referral_fee || 0) * 100) / 100
    if (referralAmount == 0) return true

    let deductions = data.splits.reduce(
      (total, split) => Math.round((total + Helpers.parseCurrency(split.referral_deduction || 0)) * 100) / 100,
      0,
    )

    if (referralAmount - deductions != 0) {
      setError(
        'referral_not_deducted',
        `The combined referral deduction amounts ($${Helpers.formatDecimal(deductions, 2)}) do not match the referral fee amount ($${Helpers.formatDecimal(referralAmount, 2)}).`,
      )
      return false
    } else {
      clearErrors('referral_not_deducted')
      return true
    }
  }

  const isSplitsApproved = () => {
    return !associates.find((a) => !a.split_approved)
  }

  const fetchContacts = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const updateSplit = (split) => {
    let splits = [...data.splits] //TODO previous data overwriting
    splits = splits.map((s) => {
      if (s.type === split.type) {
        if (s.id == split.id) {
          return split
        }
      }

      return s
    })

    setData((prevData) => ({ ...prevData, splits: splits }))
  }

  const recalculateAssociateCommission = (updated) => {
    updated = updated || { ...data, primary_residence: transaction.primary_residence }
    let splits = updated.splits

    if (splits == undefined) {
      // Load initial values from database
      splits = associates.map((transactionContact) => {
        const associate = transactionContact.contact.associate
        return {
          ...transactionContact,
          id: transactionContact.id,
          associate_id: associate.id,
          user_id: transactionContact.contact.user_id,
          type: transactionContact.type,
          name: transactionContact.full_name,
          percent: transactionContact.split,
          cap: parseFloat(associate.cap || 0),
          commission_pct: associate.commission_pct,
          cap_pct: associates.length > 1 ? transactionContact.cap_split : 100,
          approved: transactionContact.split_approved,
        }
      })
    }

    return splits.map((transactionContact) => {
      const dataSource = loading ? transaction : data
      const capSplit = associates.length > 1 ? transactionContact.cap_split : 100
      let basis = netRetained()

      let amount = basis * (transactionContact.split / 100)
      let officeRetained = 0

      // Use standard rate when applicable
      if (dataSource.use_standard_rate) {
        const commission = Helpers.parseCurrency(dataSource.commission_blre)
        basis = basis - commission + purchasePrice() * standardCommissionRate
      }

      // Only calculate cap when associate's brokerage-side split is greater than zero
      if (amount > 0) {
        if (transactionContact.cap > 0) {
          if (updated.primary_residence && leadAssociate.user_id == transactionContact.contact.user_id) {
            amount -= officeRetained = primaryResidenceFee
          } else if (capSplit > 0) {
            const capAmount = basis * (capSplit / 100) * ((100 - transactionContact.commission_pct) / 100)

            if (transactionContact.cap > capAmount) {
              amount -= officeRetained = capAmount
            } else {
              let capFee = Math.max(Math.min(transactionContact.cap, capAmount), minimumCapFee)
              amount -= officeRetained = capFee
            }
          }
        } else {
          if (basis > 0) {
            const capAmount = basis * (capSplit / 100) * ((100 - transactionContact.commission_pct) / 100)
            amount -= officeRetained = Math.min(capAmount, minimumCapFee)
          } else {
            amount -= officeRetained = minimumCapFee
          }
        }
      }

      return {
        ...transactionContact,
        amount: amount,
        office_retained: officeRetained,
        client_rebate_deduction: transactionContact.client_rebate_deduction
          ? Helpers.formatDecimal(Helpers.parseCurrency(transactionContact.client_rebate_deduction), 2)
          : null,
        referral_deduction: transactionContact.referral_deduction
          ? Helpers.formatDecimal(Helpers.parseCurrency(transactionContact.referral_deduction), 2)
          : null,
      }
    })
  }

  const distributeClientRebateDeductions = () => {
    const rebate = Helpers.parseCurrency(data.client_rebate || 0)

    if (associates.length == 1) {
      let splits = data.splits

      if (rebate === 0) {
        splits = splits.map((split) => {
          const { client_rebate_deduction, ...rest } = split
          return rest
        })
      }

      setData({
        ...data,
        splits: splits.map((split) =>
          split.type === 'Associate'
            ? { ...split, ...(rebate > 0 ? { client_rebate_deduction: Helpers.formatDecimal(rebate, 2) } : {}) }
            : split,
        ),
      })
    } else if (data.splits) {
      if (data.client_rebate_auto_distribute) {
        distributeClientRebateEvenly(rebate)
      }
    }
  }

  const distributeClientRebateEvenly = (amount) => {
    let totalDistributed = 0

    // Distribute amounts by rounding down.
    let distributedSplits = data.splits.map((split) => {
      const exactAmount = amount * (split.percent / 100)
      const roundedAmount = Math.floor(exactAmount * 100) / 100 // Round down to 2 decimal places.
      totalDistributed += roundedAmount

      if (roundedAmount === 0) {
        const { client_rebate_deduction, ...rest } = split
        split = rest
      }

      // Return updated person object with their distributed amount.
      return split.type === 'Associate' ? { ...split, client_rebate_deduction: roundedAmount } : split
    })

    // Calculate remaining amount and add it to the lead associate.
    const remaining = amount - totalDistributed

    // Adjust for any remaining pennies, rounding to 2 decimal places.
    distributedSplits = distributedSplits.map((split) => ({
      ...split,
      client_rebate_deduction: Helpers.formatDecimal(
        remaining > 0 && owner.id == split.id
          ? Math.round((split.client_rebate_deduction + remaining) * 100) / 100
          : split.client_rebate_deduction,
        2,
      ),
    }))

    setData({
      ...data,
      splits: distributedSplits,
    })
  }

  const getEscrowDeposit2PaidDate = () => {
    let payment = incomePayments.find((payment) => payment.type === '2nd Escrow Deposit' && payment.paid)
    return payment ? <span className="text-sm font-semibold normal-case text-green-600">(Received {payment.created_at})</span> : undefined
  }

  const togglePrimaryResidence = (checked) => {
    let updated = {
      ...data,
      primary_residence: checked,
      personal_investment_property: false,
    }

    let clientRebateAmount = 0
    const distributedSplits = recalculateAssociateCommission(updated).map((split) => {
      if (!split.is_transaction_owner) return split

      if (checked) {
        // Rebate 100% of associate's net commission
        clientRebateAmount = Helpers.formatDecimal(split.amount, 2)
      }

      if (clientRebateAmount == 0) {
        const { client_rebate_deduction, ...rest } = split
        return rest
      }

      return { ...split, client_rebate_deduction: clientRebateAmount }
    })

    setData({
      ...updated,
      client_rebate: clientRebateAmount,
      client_rebate_auto_distribute: !checked,
      splits: distributedSplits,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (subFormIsOpen) {
      if (!reimbursementPaneRef.current || !reimbursementsRef.current) return

      // Expand the reimbursement pane, where applicable, and scroll to the save button
      if (!reimbursementPaneRef.current.isExpanded) {
        reimbursementPaneRef.current.expand()
        setTimeout(() => reimbursementsRef.current.scrollToSaveButton(), 300)
      } else {
        reimbursementsRef.current.scrollToSaveButton()
      }

      return
    }

    onSubmit(event)
  }

  return (
    <Dialog
      ref={scrollRef}
      cancelText="Close"
      focusRef={focusRef}
      footerActions={
        transaction.editable && (
          <Button form="transactionForm" type="submit" theme="solid" disabled={disabled}>
            Save Changes
          </Button>
        )
      }
      position="top"
      show={open}
      size={transaction.editable ? 'max-w-6xl' : 'max-w-2xl'}
      title={data.name}
      onClosed={onClosed}
    >
      {data && (
        <form id="transactionForm" className="space-y-4" onSubmit={handleSubmit}>
          <div className="sm:flex sm:justify-center sm:gap-6">
            {transaction.editable && (
              <div className="sm:w-1/2">
                <div className="mb-4 flex items-center justify-between gap-4 px-6">
                  <label className="block text-center font-medium uppercase">
                    <span>Status</span>
                  </label>

                  <div className="flex items-center justify-center gap-4">
                    <Radio
                      id="closing_report_status_draft-worksheet"
                      name="closing_report_status_option"
                      label={<span className="text-xl font-semibold normal-case">Draft / Preliminary</span>}
                      value={data.closing_report_status === 'draft'}
                      onChange={() => setData({ ...data, closing_report_status: 'draft' })}
                    />
                    <Radio
                      id="closing_report_status_final-worksheet"
                      name="closing_report_status_option"
                      label={<span className="text-xl font-semibold normal-case">Final</span>}
                      value={data.closing_report_status === 'final'}
                      onChange={() => setData({ ...data, closing_report_status: 'final' })}
                    />
                  </div>
                </div>

                <Accordion id="report-sections" unmount={false}>
                  <Accordion.Items>
                    <Accordion.Item active={true}>
                      <Accordion.Button>
                        <div className="text-lg font-medium">General Closing Information</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        <div className="mb-4">
                          <label htmlFor="closing_at" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                            {
                              {
                                Buyer: 'Closing Date',
                                Seller: 'Closing Date',
                                Referral: 'Closing Date',
                                Landlord: 'Lease Start Date',
                                Tenant: 'Lease Start Date',
                                'Fee Only': 'Date of Service',
                              }[transaction.type]
                            }
                          </label>
                          <DatePicker
                            date={data.closing_at && new Date(data.closing_at)}
                            onChange={(date) => setData({ ...data, closing_at: date || null })}
                          />
                        </div>

                        {['Buyer', 'Seller'].some((type) => type.includes(transaction.type)) && (
                          <Fragment>
                            <TextInput
                              ref={focusRef}
                              label="Total Purchase Price"
                              name="total_purchase_price"
                              icon={<i className="fas fa-dollar-sign"></i>}
                              value={data.total_purchase_price}
                              error={errors.total_purchase_price}
                              onBlur={() =>
                                setData({
                                  ...data,
                                  total_purchase_price: Helpers.formatDecimal(Helpers.parseCurrency(data.total_purchase_price), 2),
                                })
                              }
                              onChange={(value) =>
                                setData((prevData) => ({
                                  ...prevData,
                                  total_purchase_price: Helpers.sanitizeCurrencyInput(value),
                                }))
                              }
                              onFocus={(e) => e.target.select()}
                              clearable
                              disabled={!isIncomeCommissionPaymentsPending()}
                            />

                            <TextInput
                              label={transaction.type === 'Buyer' ? 'Seller Concessions' : 'Closing Cost Credit to Buyer'}
                              name="closing_cost"
                              icon={<i className="fas fa-dollar-sign"></i>}
                              value={data.closing_cost}
                              error={errors.closing_cost}
                              onBlur={() =>
                                setData({
                                  ...data,
                                  closing_cost: Helpers.formatDecimal(Helpers.parseCurrency(data.closing_cost), 2),
                                })
                              }
                              onChange={(value) =>
                                setData((prevData) => ({
                                  ...prevData,
                                  closing_cost: Helpers.sanitizeCurrencyInput(value),
                                }))
                              }
                              onFocus={(e) => e.target.select()}
                              clearable
                              disabled={!isIncomeCommissionPaymentsPending()}
                            />
                          </Fragment>
                        )}
                      </Accordion.Panel>
                    </Accordion.Item>

                    {['Buyer', 'Seller'].some((type) => type.includes(transaction.type)) && (
                      <Accordion.Item
                        warning={
                          errors.escrow_coop_broker ||
                          errors.escrow_closing_attorney ||
                          errors.escrow_buyer ||
                          errors.escrow_seller ||
                          errors.escrow_deposit1
                        }
                      >
                        <Accordion.Button>
                          <div className="text-lg font-medium">
                            Escrow {transaction.escrow_held_by_blre ? 'amounts to be held by BLRE' : 'Amounts'}
                          </div>
                        </Accordion.Button>

                        <Accordion.Panel>
                          <TextInput
                            label={
                              <div className="flex items-center gap-1.5">
                                <span>Initial Escrow Deposit</span>
                                {incomePayments
                                  .filter((payment) => payment.type === 'Initial Escrow Deposit' && payment.paid)
                                  .map((payment) => (
                                    <span className="text-sm font-semibold normal-case text-green-600" key={payment.id}>
                                      (Received {payment.created_at})
                                    </span>
                                  ))}
                              </div>
                            }
                            name="escrow_deposit1"
                            icon={<i className="fas fa-dollar-sign"></i>}
                            value={data.escrow_deposit1}
                            error={errors.escrow_deposit1}
                            onBlur={() =>
                              setData({
                                ...data,
                                escrow_deposit1: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit1), 2),
                              })
                            }
                            onChange={(value) =>
                              setData((prevData) => ({
                                ...prevData,
                                escrow_deposit1: Helpers.sanitizeCurrencyInput(value),
                              }))
                            }
                            onFocus={(e) => e.target.select()}
                            clearable
                            disabled={
                              Boolean(transaction.escrow_held_by_blre) &&
                              incomePayments?.find((payment) => payment.paid && payment.type === 'Initial Escrow Deposit')
                            }
                          />

                          <TextInput
                            label={
                              <div className="flex items-center gap-1.5">
                                <span>2nd Escrow Deposit</span>
                                {getEscrowDeposit2PaidDate()}
                              </div>
                            }
                            name="escrow_deposit2"
                            icon={<i className="fas fa-dollar-sign"></i>}
                            value={data.escrow_deposit2}
                            error={errors.escrow_deposit2}
                            onBlur={() =>
                              setData({
                                ...data,
                                escrow_deposit2: Helpers.formatDecimal(Helpers.parseCurrency(data.escrow_deposit2), 2),
                              })
                            }
                            onChange={(value) =>
                              setData((prevData) => ({
                                ...prevData,
                                escrow_deposit2: Helpers.sanitizeCurrencyInput(value),
                              }))
                            }
                            onFocus={(e) => e.target.select()}
                            clearable
                            disabled={Boolean(transaction.escrow_held_by_blre) && !isIncomeCommissionPaymentsPending()}
                          />

                          {Boolean(transaction.escrow_held_by_blre) &&
                            totalHeldInEscrow() > 0 &&
                            totalHeldInEscrow() != totalDisbursements() && (
                              <div className="space-y-4">
                                <Heading>Additional Escrow Disbursements</Heading>

                                {totalHeldInEscrow() - totalBLRECommission() && (
                                  <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                                    <div className="flex items-end divide-x divide-gray-300">
                                      <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Escrow Surplus</span>
                                      <span className="flex w-32 items-center justify-center bg-white px-4 py-2 font-bold">
                                        <span className="truncate">
                                          ${Helpers.formatDecimal(totalHeldInEscrow() - totalBLRECommission() || 0, 2)}
                                        </span>
                                      </span>
                                    </div>

                                    <div className="flex items-end divide-x divide-gray-300">
                                      <span className="flex-1 bg-gray-100 px-4 py-2 font-bold">Total Disbursements</span>
                                      <span className="flex w-32 items-center justify-center bg-white px-4 py-2 font-bold">
                                        <span className="truncate">${Helpers.formatDecimal(totalDisbursements() || 0, 2)}</span>
                                      </span>
                                    </div>

                                    <div
                                      className={classNames('flex items-end divide-x divide-gray-300', {
                                        'text-red-600': totalDisbursements() > totalHeldInEscrow() - totalBLRECommission(),
                                      })}
                                    >
                                      <span
                                        className={classNames(
                                          'flex-1 px-4 py-2 font-bold',
                                          totalDisbursements() > totalHeldInEscrow() - totalBLRECommission() ? 'bg-red-50' : 'bg-gray-100',
                                        )}
                                      >
                                        Amount to disburse
                                      </span>
                                      <span
                                        className={classNames(
                                          'flex w-32 items-center justify-center px-4 py-2 font-bold',
                                          totalDisbursements() > totalHeldInEscrow() - totalBLRECommission() ? 'bg-red-50' : 'bg-white',
                                        )}
                                      >
                                        <span className="truncate">
                                          $
                                          {Helpers.formatDecimal(
                                            totalHeldInEscrow() - totalBLRECommission() - totalDisbursements() || 0,
                                            2,
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {errors.escrow_coop_broker && <Alert heading={errors.escrow_coop_broker} type="danger" />}

                                {[
                                  { value: 'escrow_coop_broker', label: 'Cooperating Broker' },
                                  { value: 'escrow_closing_attorney', label: 'Closing Attorney' },
                                  { value: 'escrow_buyer', label: 'Buyer' },
                                  { value: 'escrow_seller', label: 'Seller' },
                                ].map((party, index) => (
                                  <div className="mb-4 rounded-md border border-gray-300 bg-gray-50 px-3 pt-3" key={index}>
                                    <TextInput
                                      label={party.label}
                                      name={party.value}
                                      icon={<i className="fas fa-dollar-sign"></i>}
                                      value={data[party.value]}
                                      error={errors[party.value]}
                                      onBlur={() =>
                                        setData({
                                          ...data,
                                          [party.value]: Helpers.formatDecimal(Helpers.parseCurrency(data[party.value]), 2),
                                        })
                                      }
                                      onChange={(value) =>
                                        setData((prevData) => ({
                                          ...prevData,
                                          [party.value]: value || 0,
                                        }))
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {data[party.value] != undefined && (
                                      <TextArea
                                        label="Mail to (include recipient):"
                                        error={errors[`${party.value}_info`]}
                                        name={`${party.value}_info`}
                                        value={data[`${party.value}_info`]}
                                        onChange={(value) => setData({ ...data, [`${party.value}_info`]: value })}
                                        required
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                        </Accordion.Panel>
                      </Accordion.Item>
                    )}

                    <Accordion.Item warning={errors.cooperating_agent_commission_not_set}>
                      <Accordion.Button>
                        <div className="text-lg font-medium">Commission</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        {['Buyer', 'Seller'].some((type) => type.includes(transaction.type)) && (
                          <Fragment>
                            <div className="mb-4 space-y-2">
                              <label className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
                                <span>Commissions based on:</span>
                              </label>

                              <div className="flex justify-center space-x-4">
                                <Radio
                                  id="commission_basis_gross"
                                  name="commission_basis"
                                  label="Gross Purchase Price"
                                  value={data.commission_basis === 'gross'}
                                  onChange={() => setData({ ...data, commission_basis: 'gross' })}
                                  disabled={!isIncomeCommissionPaymentsPending()}
                                />
                                <Radio
                                  id="commission_basis_net"
                                  name="commission_basis"
                                  label="Net Purchase Price"
                                  value={data.commission_basis === 'net'}
                                  onChange={() => setData({ ...data, commission_basis: 'net' })}
                                  disabled={!isIncomeCommissionPaymentsPending()}
                                />
                              </div>
                            </div>

                            <AmountPercentInput
                              label={
                                transaction.type.includes('Buyer') ? (
                                  <div className="-mt-1 flex items-end">
                                    <div className="w-24">
                                      <img className="object-fill" src="/images/R-BLRE_logo_master_800x320.png" />
                                    </div>

                                    <span className="font-bold text-green-900">Buyer Agent</span>
                                  </div>
                                ) : (
                                  <span>Buyer Agent</span>
                                )
                              }
                              name="commission_blre"
                              basis={purchasePrice()}
                              value={data[commissionFieldName('Buyer')]}
                              percent={data[commissionFieldName('Buyer') + '_pct']}
                              error={errors[commissionFieldName('Buyer')]}
                              onChange={(value, percent) =>
                                !loading &&
                                setData((prevData) => ({
                                  ...prevData,
                                  [commissionFieldName('Buyer')]: value,
                                  [commissionFieldName('Buyer') + '_pct']: percent,
                                }))
                              }
                              disabled={
                                !data.commission_basis || (transaction.type.includes('Buyer') && !isIncomeCommissionPaymentsPending())
                              }
                            />

                            {transaction.type.includes('Buyer') && (
                              <AmountPercentInput
                                label={
                                  <span>
                                    <span className="font-bold uppercase text-gray-900">Extra</span> Buyer Agent (Paid by the Buyer)
                                  </span>
                                }
                                name="commission_additional"
                                basis={purchasePrice()}
                                value={data.commission_additional}
                                percent={data.commission_additional_pct}
                                error={errors.commission_additional}
                                onChange={(value, percent) =>
                                  !loading &&
                                  setData((prevData) => ({
                                    ...prevData,
                                    commission_additional: value,
                                    commission_additional_pct: percent,
                                  }))
                                }
                                disabled={
                                  !data.commission_basis || (transaction.type.includes('Buyer') && !isIncomeCommissionPaymentsPending())
                                }
                              />
                            )}

                            {(transaction.type.includes('Seller') || administrating || cooperatingAgents.length > 0) && (
                              <AmountPercentInput
                                label={
                                  transaction.type.includes('Seller') ? (
                                    <div className="-mt-1 flex items-end">
                                      <div className="w-24">
                                        <img className="object-fill" src="/images/R-BLRE_logo_master_800x320.png" />
                                      </div>

                                      <span className="font-bold text-green-900">Seller Agent</span>
                                    </div>
                                  ) : (
                                    <span>Seller Agent</span>
                                  )
                                }
                                name="commission_cooperating"
                                basis={purchasePrice()}
                                value={data[commissionFieldName('Seller')]}
                                percent={data[commissionFieldName('Seller') + '_pct']}
                                error={
                                  errors[commissionFieldName('Seller')] ||
                                  (cooperatingParty === 'Seller' && errors.cooperating_agent_commission_not_set)
                                }
                                onChange={(value, percent) =>
                                  !loading &&
                                  setData((prevData) => ({
                                    ...prevData,
                                    [commissionFieldName('Seller')]: value,
                                    [commissionFieldName('Seller') + '_pct']: percent,
                                  }))
                                }
                                disabled={
                                  !data.commission_basis || (transaction.type.includes('Seller') && !isIncomeCommissionPaymentsPending())
                                }
                              />
                            )}

                            {(administrating || leadAssociate.user_id === auth.user.id) && (
                              <div className="space-y-4 pt-2">
                                <Heading>
                                  <div className="sm:flex sm:items-center sm:gap-1.5">
                                    <span>Additional Options</span>
                                    <span className="text-sm font-bold text-gray-900">(Lead Associate Only)</span>
                                  </div>
                                </Heading>

                                <Checkbox
                                  name="primary_residence"
                                  label={
                                    <div className="flex h-5 items-center gap-1.5">
                                      Primary Residence Transaction
                                      {!administrating && (
                                        <Tooltip
                                          placement="bottom"
                                          label="By default, your commission, less the Primary Residence Fee, will be deducted as a client rebate. You can opt to remove the client rebate if you wish to instead receive a commission check from the sale."
                                        >
                                          <i className="far fa-question-circle cursor-help text-lg text-orange-500"></i>
                                        </Tooltip>
                                      )}
                                    </div>
                                  }
                                  description={
                                    <div className="text-gray-600">
                                      {administrating ? (
                                        <span>
                                          <span className="font-semibold">{owner.full_name}</span> is{' '}
                                        </span>
                                      ) : (
                                        'Select this option to indicate you are '
                                      )}
                                      {transaction.type.includes('Buyer') ? 'purchasing this property as ' : 'selling '}
                                      {administrating ? 'their primary residence.' : 'your primary residence.'}
                                    </div>
                                  }
                                  value={data.primary_residence}
                                  onChange={(checked) => togglePrimaryResidence(checked)}
                                  disabled={data.personal_investment_property}
                                />

                                <Checkbox
                                  name="personal_investment_property"
                                  label={<div className="flex h-5 items-center gap-1.5">Personal Investment Property</div>}
                                  description={
                                    <div className="text-gray-600">
                                      {administrating ? (
                                        <span>
                                          <span className="font-semibold">{owner.full_name}</span> is{' '}
                                        </span>
                                      ) : (
                                        'Select this option to indicate you are '
                                      )}
                                      {transaction.type.includes('Buyer') ? 'purchasing ' : 'selling '}
                                      this property as{' '}
                                      {administrating ? 'a personal investment property.' : 'your personal investment property.'}
                                    </div>
                                  }
                                  value={data.personal_investment_property}
                                  onChange={(checked) =>
                                    setData({
                                      ...data,
                                      primary_residence: false,
                                      personal_investment_property: checked,
                                    })
                                  }
                                  disabled={data.primary_residence}
                                />

                                {administrating && (
                                  <Checkbox
                                    name="use_standard_rate"
                                    label={
                                      <div className="flex h-5 items-center gap-1.5">
                                        <Tooltip
                                          placement="bottom"
                                          label="When enabled, Sponsors and CAP amounts will instead be calculated using the standard commission rate of 2.5%. The lead Associate's brokerage-side split will be recalculated based on that rate to compensate for the difference."
                                        >
                                          <i className="far fa-question-circle cursor-help text-lg text-orange-500"></i>
                                        </Tooltip>
                                        Use Standard Rate (2.5%) for Sponsors & CAP
                                      </div>
                                    }
                                    value={data.use_standard_rate}
                                    onChange={(checked) => setData({ ...data, use_standard_rate: checked })}
                                  />
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}

                        {['Landlord', 'Tenant', 'Fee Only'].some((type) => type.includes(transaction.type)) && (
                          <TextInput
                            label={
                              <div className="-mt-1 flex items-end">
                                <div className="w-24">
                                  <img className="object-fill" src="/images/R-BLRE_logo_master_800x320.png" />
                                </div>

                                <span className="font-bold text-green-900">Agent Commission</span>
                              </div>
                            }
                            name="commission_blre"
                            icon={<i className="fas fa-dollar-sign"></i>}
                            value={data.commission_blre}
                            error={errors.commission_blre}
                            onBlur={() =>
                              setData({
                                ...data,
                                commission_blre: Helpers.formatDecimal(Helpers.parseCurrency(data.commission_blre), 2),
                              })
                            }
                            onChange={(value) =>
                              !loading &&
                              setData((prevData) => ({
                                ...prevData,
                                commission_blre: Helpers.sanitizeCurrencyInput(value),
                              }))
                            }
                            onFocus={(e) => e.target.select()}
                            clearable
                            disabled={!isIncomeCommissionPaymentsPending()}
                          />
                        )}

                        {['Fee Only'].some((type) => type.includes(transaction.type)) && (
                          <TextArea
                            label="Fee Description"
                            name="fee_description"
                            value={data.fee_description}
                            error={errors.fee_description}
                            rows="2"
                            onChange={(value) => setData({ ...data, fee_description: value })}
                            required
                            multiline
                          />
                        )}
                      </Accordion.Panel>
                    </Accordion.Item>

                    {['Landlord', 'Seller', 'Fee Only'].some((type) => type.includes(transaction.type)) && (
                      <Accordion.Item
                        ref={reimbursementPaneRef}
                        warning={Object.keys(errors).some((key) => key.includes('reimbursements'))}
                      >
                        <Accordion.Button>
                          <div className="text-lg font-medium">Reimbursements</div>
                        </Accordion.Button>

                        <Accordion.Panel>
                          <Reimbursements
                            ref={reimbursementsRef}
                            associates={associates}
                            clearErrors={clearErrors}
                            data={data}
                            errors={errors}
                            setError={setError}
                            onUpdateSplit={(data) => updateSplit(data)}
                            onChanged={(newData) => !loading && setData((prevData) => ({ ...prevData, ...newData }))}
                            onFormVisibilityChange={setSubFormOpen}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    )}

                    <Accordion.Item
                      warning={
                        errors.referral_not_deducted ||
                        errors.referral_fee ||
                        errors.referral_id ||
                        errors.referral_name ||
                        errors.referral_info
                      }
                    >
                      <Accordion.Button>
                        <div className="text-lg font-medium">Referral</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        <Referrals
                          associates={associates}
                          data={data}
                          errors={errors}
                          transactionType={transaction.type}
                          onChanged={(data) => !loading && setData(data)}
                          onFetchContacts={(value) => fetchContacts(value)}
                          onUpdateSplit={(data) => updateSplit(data)}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>

                    {['Buyer', 'Seller'].some((type) => type.includes(transaction.type)) && (
                      <Accordion.Item error={Object.keys(errors).find((key) => key.includes('client_rebate'))}>
                        <Accordion.Button>
                          <div className="text-lg font-medium">Client Rebate</div>
                        </Accordion.Button>

                        <Accordion.Panel>
                          <ClientRebate
                            associates={associates}
                            data={data}
                            errors={errors}
                            onChanged={(value, key) => !loading && setData((prevData) => ({ ...prevData, [key]: value }))}
                            updateSplit={(data) => updateSplit(data)}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    )}

                    {['Buyer', 'Seller'].some((type) => type.includes(transaction.type)) && (
                      <Accordion.Item
                        error={
                          errors.community_protector_rebate ||
                          errors.community_protector_rebate_info ||
                          errors.mygivebackprogram_rebate ||
                          errors.mygivebackprogram_rebate_info
                        }
                      >
                        <Accordion.Button>
                          <div className="text-lg font-medium">Rebate Programs</div>
                        </Accordion.Button>

                        <Accordion.Panel>
                          <div className="space-y-4">
                            <div className="rounded-md border border-gray-300 bg-gray-50 px-3 pt-3">
                              <TextInput
                                label="Community Protector Rebate"
                                name="community_protector_rebate"
                                icon={<i className="fas fa-dollar-sign"></i>}
                                value={data.community_protector_rebate}
                                error={errors.community_protector_rebate}
                                onBlur={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    community_protector_rebate: Helpers.formatDecimal(
                                      Helpers.parseCurrency(data.community_protector_rebate || 0),
                                      2,
                                    ),
                                  }))
                                }
                                onChange={(value) =>
                                  !loading &&
                                  setData((prevData) => ({
                                    ...prevData,
                                    community_protector_rebate: value,
                                  }))
                                }
                                onFocus={(e) => e.target.select()}
                              />

                              <TextArea
                                label={<span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Mail Rebate to:</span>}
                                name="community_protector_rebate_info"
                                value={data.community_protector_rebate_info}
                                error={errors.community_protector_rebate_info}
                                onChange={(value) => setData({ ...data, community_protector_rebate_info: value })}
                              />
                            </div>

                            <div className="rounded-md border border-gray-300 bg-gray-50 px-3 pt-3">
                              <TextInput
                                label="MyGiveBackProgram Rebate"
                                name="mygivebackprogram_rebate"
                                icon={<i className="fas fa-dollar-sign"></i>}
                                value={data.mygivebackprogram_rebate}
                                error={errors.mygivebackprogram_rebate}
                                onBlur={() =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    mygivebackprogram_rebate: Helpers.formatDecimal(
                                      Helpers.parseCurrency(data.mygivebackprogram_rebate || 0),
                                      2,
                                    ),
                                  }))
                                }
                                onChange={(value) =>
                                  !loading &&
                                  setData((prevData) => ({
                                    ...prevData,
                                    mygivebackprogram_rebate: value,
                                  }))
                                }
                                onFocus={(e) => e.target.select()}
                              />

                              <TextArea
                                label={<span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Mail Rebate to:</span>}
                                name="mygivebackprogram_rebate_info"
                                value={data.mygivebackprogram_rebate_info}
                                error={errors.mygivebackprogram_rebate_info}
                                onChange={(value) => !loading && setData({ ...data, mygivebackprogram_rebate_info: value })}
                              />
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                    )}

                    <Accordion.Item error={Object.keys(errors).find((key) => key.includes('additional_compensation'))}>
                      <Accordion.Button>
                        <div className="text-lg font-medium">Additional Compensation</div>
                      </Accordion.Button>

                      <Accordion.Panel>
                        <AdditionalCompensation
                          associates={associates}
                          data={data}
                          errors={errors}
                          onChanged={(newData) => !loading && setData((prevData) => ({ ...prevData, additional_compensation: newData }))}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion.Items>
                </Accordion>
              </div>
            )}

            <div className={transaction.editable && (isIncomeCommissionPaymentsPending() || administrating) ? 'sm:w-1/2' : undefined}>
              <div className="space-y-4">
                {transaction.editable && !isSplitsApproved() && (
                  <Alert
                    heading="The Associate Co-Servicing Agreement has not been accepted by one or more Associates."
                    subtext="Editing the Closing Worksheeet is unavailable until all Associates have approved the Associate Co-Servicing Agreement."
                    type="danger"
                  />
                )}

                {!isIncomeCommissionPaymentsPending() &&
                  (Boolean(transaction.escrow_held_by_blre) ? (
                    <Alert
                      heading="All escrow deposit funds have been received"
                      subtext="Fields that affect the received escrow amount(s) have been disabled."
                      type="danger"
                    />
                  ) : (
                    <Alert
                      heading="All commission funds have been received"
                      subtext="Fields that affect the received commission amount(s) have been disabled."
                      type="danger"
                    />
                  ))}

                <Summary transaction={{ ...data, type: transaction.type }} />

                {data.reimbursements?.length > 0 && (
                  <Fragment>
                    <Heading>Reimbursements</Heading>
                    <ReimbursementsSummary data={data} />
                  </Fragment>
                )}

                <Heading>Commissions</Heading>
                <CommissionsSummary data={data} />
              </div>
            </div>
          </div>
        </form>
      )}
    </Dialog>
  )
})
