import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import { router } from '@inertiajs/react'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { useForm, usePage } from '@inertiajs/react'

export default function AssociateCreate({ open, onClosed, record }) {
  const focusRef = useRef()
  const { supervisors } = usePage().props
  const { clearErrors, data, setData, errors, setError } = useForm()
  const [submitting, setSubmitting] = useState(false)

  const [addressTypes] = useState([
    { label: 'Home', value: 'Home' },
    { label: 'Work', value: 'Work' },
    { label: 'Other', value: 'Other' },
  ])
  const [emailTypes, setEmailTypes] = useState([
    { label: 'Personal', value: 'Personal' },
    { label: 'Work', value: 'Work' },
    { label: 'Other', value: 'Other' },
  ])
  const [phoneTypes] = useState([
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Work', value: 'Work' },
    { label: 'Home', value: 'Home' },
    { label: 'Fax', value: 'Fax' },
  ])
  const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ]
  const licensedStates = [
    { value: 'MA', label: 'MA' },
    { value: 'NH', label: 'NH' },
    { value: 'RI', label: 'RI' },
  ]

  useEffect(() => {
    if (record) {
      setData({
        id: null,
        first_name: '',
        last_name: '',
        supervisor_id: null,
        sponsor_id: null,
        sponsor1stTier: null,
        required_transaction_support: false,
        support_vps: [{}],
        emails: [
          { type: 'Work', primary: true },
          { type: 'Personal', primary: true },
        ],
        addresses: [{ type: 'Home', primary: true }],
        phone_numbers: [{ type: 'Mobile', primary: true }],
        commission_pct: '70',
        cap: '24000',
      })
    }
  }, [record])

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const showOption = (option) => {
    return option.label ? (
      option.label.name
    ) : (
      <div className="group flex items-center space-x-3" key={option.id}>
        {option.avatar ? (
          <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
        ) : (
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
            <div className="text-base font-medium tracking-wide">{option.initials}</div>
          </div>
        )}

        <div>
          <div className="space-x-1">
            <span className="space-x-1 font-medium text-gray-900">
              <span>{option.name}</span>
              {option.industry && <span className="text-gray-500">in {option.industry}</span>}
            </span>
          </div>

          {option.company && <div className="text-gray-500">{option.company}</div>}
        </div>
      </div>
    )
  }

  const onClosing = () => {
    clearErrors()
    onClosed()
  }

  const submit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    clearErrors()

    router.post(
      route('admin.associates.store'),
      {
        ...data,
        emails: data.emails.filter((e) => e.email),
        phone_numbers: data.phone_numbers.filter((e) => e.phone),
        addresses: data.addresses.filter((e) => e.street),
        support_vps: data.support_vps.filter((e) => e.supervisor_id || e.states),
      },
      {
        onSuccess: () => onClosing(),
        onError: (errors) => setError(errors),
        onFinish: () => {
          setSubmitting(false)
        },
      },
    )
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="service-form" disabled={submitting}>
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      show={open}
      size="max-w-2xl"
      title={data.id ? data.name : 'New'}
      subTitle="Associate"
      onClosed={onClosing}
    >
      <form id="service-form" className="mb-5" onSubmit={submit}>
        <div className="space-y-6">
          <TextInput
            ref={focusRef}
            label="First Name"
            name="first_name"
            value={data.first_name}
            error={errors.first_name}
            onChange={(value) => setData({ ...data, first_name: value })}
            onBlur={() => setData({ ...data, first_name: Helpers.capitalize(data.first_name) })}
            required
          />

          <TextInput
            label="Last Name"
            name="last_name"
            value={data.last_name}
            error={errors.last_name}
            onChange={(value) => setData({ ...data, last_name: value })}
            onBlur={() => setData({ ...data, last_name: Helpers.capitalize(data.last_name) })}
            required
          />

          <div>
            <Heading>
              {(errors.commission_pct || errors.cap) && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
              Compensation Plan
            </Heading>

            <div className="mt-4 sm:flex sm:justify-between sm:gap-8">
              <div className="flex flex-1 justify-end">
                <TextInput
                  name="commission_pct"
                  error={errors.commission_pct}
                  label={
                    <span>
                      Commission Rate<span className="pl-1 text-red-600">*</span>
                    </span>
                  }
                  icon={<i className="fal fa-percent"></i>}
                  iconPosition="right"
                  size="w-40"
                  type="number"
                  value={data.commission_pct}
                  onChange={(value) => setData({ ...data, commission_pct: value })}
                />
              </div>

              <div className="flex-1">
                <TextInput
                  label="Brokerage Rate"
                  icon={<i className="fal fa-percent"></i>}
                  iconPosition="right"
                  type="number"
                  size="w-40"
                  value={100 - data.commission_pct}
                  disabled={true}
                />
              </div>
            </div>

            <div className="sm:flex sm:justify-between sm:gap-8">
              <div className="flex-1">
                <TextInput
                  error={errors.cap}
                  name="cap"
                  label="Annual CAP Amount"
                  icon={<i className="fal fa-dollar-sign"></i>}
                  type="number"
                  value={data.cap}
                  onChange={(value) => setData({ ...data, cap: value })}
                />
              </div>

              <div className="flex-1">
                <TextInput
                  label="Annual CAP Residual"
                  icon={<i className="fal fa-dollar-sign"></i>}
                  type="number"
                  value={data.cap ? data.cap - 3000 : 0}
                  disabled={true}
                />
              </div>
            </div>

            <DatePicker
              date={data.cap_anniversary_date && new Date(data.cap_anniversary_date)}
              error={errors.cap_anniversary_date}
              label="CAP Anniversary Date"
              onChange={(date) => setData({ ...data, cap_anniversary_date: date })}
              required
            />
          </div>

          <div>
            <Heading>Options</Heading>

            <div className="space-y-4">
              <Checkbox
                name="requires_transaction_support"
                label="Requires Transaction Support"
                value={data.requires_transaction_support}
                onChange={(checked) => {
                  clearErrors('support_associate_id')
                  setData({ ...data, requires_transaction_support: checked })
                }}
              />

              <Select
                error={errors.support_associate_id}
                name="support_associate_id"
                label="Transaction Support Associate"
                optionLabel={(option) => showOption(option)}
                optionValue={(option) => option.id}
                placeholder="Search Associates"
                value={data.support_associate}
                onChange={(selected) => {
                  setData({ ...data, support_associate_id: selected?.associate_id || null, support_associate: selected })
                }}
                onInputChanged={(value) => fetchAssociates(value)}
                async
                disabled={!data.requires_transaction_support}
                required={data.requires_transaction_support}
              />
            </div>
          </div>

          <div className="!mt-0">
            <Heading>Roles</Heading>

            <div className="space-y-4">
              <Checkbox
                name="roles_support_vp"
                label="Support Vice President"
                value={data.support_vp}
                onChange={(checked) => setData({ ...data, support_vp: checked, pal: false })}
              />

              <Checkbox
                name="roles_pal"
                label="PAL"
                value={data.pal}
                onChange={(checked) => setData({ ...data, pal: checked, support_vp: false })}
              />
            </div>
          </div>

          <div>
            <div id="support_vps" className="mb-5">
              <Heading noMargin>
                {Object.keys(errors).find((key) => key.includes('support_vps')) && (
                  <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>
                )}
                Support Vice Presidents
              </Heading>

              {Object.keys(errors).find((key) => key.includes('support_vps')) && (
                <div className="mx-1 mt-2 text-red-600">Complete the highlighted fields.</div>
              )}

              <MultipleInputBlock
                data={data.support_vps}
                onChange={(value) => setData({ ...data, support_vps: value })}
                hasPrimary={false}
                render={({ focusRef, data: item, index, onChange }) => (
                  <div className="flex-1 sm:flex sm:flex-wrap sm:gap-4">
                    <Select
                      classes="flex-1"
                      name="supervisor_id"
                      options={supervisors.filter(
                        (option) =>
                          option.value == item.supervisor_id ||
                          !data.support_vps.find((selected) => selected.supervisor_id == option.value),
                      )}
                      placeholder="Select Associate"
                      value={item.supervisor_id}
                      error={errors[`support_vps.${index}.supervisor_id`]}
                      onChange={(selected) => onChange({ ...item, supervisor_id: selected && selected.value })}
                      hideErrorMessage
                    />

                    <Select
                      ref={focusRef}
                      classes="w-48"
                      name="svp_state"
                      options={licensedStates.filter(
                        (option) =>
                          item.states?.split(',').indexOf(option.value) >= 0 ||
                          !data.support_vps.find((selected) => selected.states?.split(',').indexOf(option.value) >= 0),
                      )}
                      placeholder="State(s)"
                      value={licensedStates.filter((state) => (item.states || '').split(',').indexOf(state.value) >= 0)}
                      error={errors[`support_vps.${index}.states`]}
                      isClearable={false}
                      onChange={(selected) =>
                        onChange({
                          ...item,
                          states: selected?.reduce((carry, item) => (carry += (carry != '' ? ',' : '') + item.value), ''),
                        })
                      }
                      multiple
                      hideErrorMessage
                    />
                  </div>
                )}
              />
            </div>

            <Heading>Primary Sponsor</Heading>

            <Select
              error={errors.manager_id}
              name="sponsor_id"
              optionLabel={(option) => showOption(option)}
              optionValue={(option) => option.id}
              placeholder="Search Associates"
              value={data.sponsor1stTier}
              onChange={(selected) => setData({ ...data, sponsor_id: selected?.associate_id || null, sponsor1stTier: selected })}
              onInputChanged={(value) => fetchAssociates(value)}
              async
            />
          </div>

          <div className="mb-5">
            <Heading>
              {errors.phone_numbers && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
              Phone Numbers
            </Heading>

            {errors.phone_numbers && <div className="mx-1 mt-2 text-red-600">{errors.phone_numbers}</div>}

            <MultipleInputBlock
              data={data.phone_numbers}
              onChange={(value) => setData({ ...data, phone_numbers: value })}
              hasPrimary
              render={({ focusRef, data, index, onChange }) => (
                <div className="flex-1 sm:flex sm:flex-wrap">
                  <TextInput
                    ref={focusRef}
                    classes="sm:flex-grow"
                    name="phone"
                    placeholder="Number"
                    value={data.phone || ''}
                    onChange={(value) => onChange(value, 'phone')}
                  />

                  <Select
                    classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                    name="type"
                    options={phoneTypes}
                    placeholder="Type"
                    value={data.type}
                    isClearable={false}
                    onChange={(selected) => onChange(selected && selected.value, 'type')}
                    creatable
                  />

                  {Boolean(errors[`phone_numbers.${index}.value`]) && (
                    <div className="mx-1 mt-2 text-red-600">{errors[`phone_numbers.${index}.value`]}</div>
                  )}
                </div>
              )}
            />
          </div>

          <div id="emails" className="mb-5">
            <Heading>
              {errors.emails && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
              Emails
            </Heading>

            {errors.emails && <div className="mx-1 mt-2 text-red-600">{errors.emails}</div>}

            <MultipleInputBlock
              data={data.emails}
              onChange={(value) => setData({ ...data, emails: value })}
              hasError={Object.keys(errors).some((k) => k.indexOf('emails'))}
              hasPrimary
              render={({ focusRef, data, index, onChange }) => (
                <div className="flex-1 sm:flex sm:flex-wrap">
                  <TextInput
                    ref={focusRef}
                    classes="sm:flex-grow"
                    error={errors[`emails.${index}.email`]}
                    name="email"
                    placeholder="Email"
                    value={data.email ?? ''}
                    onChange={(value) => onChange(value, 'email')}
                    hideErrorMessage
                  />

                  <Select
                    classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                    name="type"
                    options={emailTypes}
                    placeholder="Type"
                    value={data.type}
                    isClearable={false}
                    onChange={(selected) => {
                      if (selected) {
                        if (emailTypes.find((i) => i.value === selected.value) == undefined) {
                          setEmailTypes(emailTypes.concat(selected))
                        }
                      }

                      onChange(selected && selected.value, 'type')
                    }}
                    creatable
                  />

                  {Boolean(errors[`emails.${index}.value`]) && (
                    <div className="mx-1 mt-2 text-red-600">{errors[`emails.${index}.value`]}</div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="mb-5">
            <Heading>
              {errors.addresses && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
              Home Address
            </Heading>

            {errors.addresses && <div className="mx-1 mt-2 text-red-600">{errors.addresses}</div>}

            <MultipleInputBlock
              data={data.addresses}
              onChange={(value) => setData({ ...data, addresses: value })}
              hasPrimary
              render={({ focusRef, data, index, onChange }) => (
                <div className="flex-1">
                  <TextInput
                    ref={focusRef}
                    classes="mb-1"
                    name="street"
                    value={data.street ?? ''}
                    placeholder="Street address"
                    onChange={(value) => onChange(value, 'street')}
                  />
                  <TextInput
                    classes="mb-1"
                    name="street2"
                    value={data.street2 ?? ''}
                    placeholder="Street address line 2"
                    onChange={(value) => onChange(value, 'street2')}
                  />
                  <TextInput
                    classes="mb-1"
                    name="city"
                    value={data.city ?? ''}
                    placeholder="City"
                    onChange={(value) => onChange(value, 'city')}
                  />
                  <Select
                    classes="mb-1"
                    name="state"
                    options={states}
                    placeholder="State"
                    value={data.state}
                    isClearable={false}
                    onChange={(selected) => onChange(selected && selected.value, 'state')}
                  />
                  <TextInput
                    classes="mb-1"
                    name="postal_code"
                    value={data.postal_code ?? ''}
                    placeholder="ZIP code"
                    onChange={(value) => onChange(value, 'postal_code')}
                  />

                  <Select
                    classes="mb-1"
                    name="type"
                    options={addressTypes.filter((type) => type.value != 'Work')}
                    placeholder="Type"
                    value={data.type}
                    isClearable={false}
                  />

                  {Boolean(errors[`addresses.${index}.value`]) && (
                    <div className="mx-1 mt-2 text-red-600">{errors[`addresses.${index}.value`]}</div>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        {record.id && (
          <div className="mt-6 space-y-3 border-t border-dashed border-gray-300 pt-6">
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Created</label>
              <div className="flex-1">{record.created_at}</div>
            </div>
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Last Updated</label>
              <div className="flex-1">{record.updated_at}</div>
            </div>
          </div>
        )}
      </form>
    </SlideOver>
  )
}
