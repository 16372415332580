import { Fragment, useEffect, useState } from 'react'
import Alert from '@/Shared/Alert'
import ApproveReviewDialog from './ApproveReviewDialog'
import Button from '@/Shared/Button'
import CancelReviewDialog from './CancelReviewDialog'
import Card from './Card'
import Checklist from '@/Shared/Checklists/Index'
import classNames from 'classnames'
import { ContactConsumer, ContactProvider } from './People/ContactContext'
import Details from '@/Shared/Layouts/Details'
import PausedComplianceReviewDialog from './PausedComplianceReviewDialog'
import FileManager from '@/Shared/FileManager/Index'
import Forms from './Forms/Index'
import Main from '@/Shared/Layouts/Main'
import Payments from './Payments/Index'
import PauseReviewDialog from './PauseReviewDialog'
import RequestReviewDialog from './RequestReviewDialog'
import SearchContactDialog from './People/Search/Dialog'
import Summary from './Summary'
import Tab from '@/Shared/Tabs/Tab'
import Timeline from '@/Shared/Timeline/Index'
import TransactionContactEdit from './People/Edit'
import TransactionContacts from './People/Index'
import TransactionEdit from '@/Pages/Transactions/Edit'
import TransactionMobile from '@/Shared/Transaction/Mobile'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import Helpers from '@/utils/helpers'
import { DatesProvider } from './Forms/DatesContext'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

function TransactionsShow({ transaction }) {
  const { user } = usePage().props.auth
  const administrating = Helpers.isAdminOrHR(user)
  const [activeTab, setActiveTab] = useState('checklist')
  const [beforeApproveReview, setBeforeApproveReview] = useState(false)
  const [beforeCancelReview, setBeforeCancelReview] = useState(false)
  const [beforeRequestingReview, setBeforeRequestingReview] = useState(false)
  const [beforePauseReview, setBeforePauseReview] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(null)
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const [complianceReviewReportOpen, setComplianceReviewReportOpen] = useState(false)
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  })
  const myContact = transaction.contacts.find((tc) => tc.is_me)
  const closingFiles = transaction.directories.find((directory) => directory.name === 'Closing').files
  const filesAwaitingReviewCount = getFilesAwaitingReview()
  const notApplicableFilesCount = getNotApplicableFiles()
  const declinedFilesCount = getDeclinedFiles()
  const pendingPaymentsCount = getPendingPayments()
  const approvable = pendingPaymentsCount + filesAwaitingReviewCount + declinedFilesCount + notApplicableFilesCount == 0

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  useEffect(() => {
    if ((Helpers.isHR(user) || transaction.reviewing) && activeTab === 'checklist') {
      setActiveTab('summary')
    }
  }, [transaction.status])

  function getFilesAwaitingReview() {
    if (!transaction.reviewing) return []
    return closingFiles.filter((file) => !file.status || file.replaced).length
  }

  function getNotApplicableFiles() {
    if (!transaction.reviewing && !Boolean(transaction.paused_at)) return []
    return closingFiles.filter((file) => file.status === 'Not Applicable').length
  }

  function getDeclinedFiles() {
    if (!transaction.reviewing && !Boolean(transaction.paused_at)) return []
    return closingFiles.filter((file) => file.status === 'Declined').length
  }

  function getPendingPayments() {
    if (!transaction.reviewing) return []
    return transaction.payments.filter((payment) => !payment.paid).length
  }

  const handleBeginReview = () => {
    router.post(route('transactions.change-status', transaction.id), { closing_type: 'Under Review' })
  }

  const handleCancelReview = (data) => {
    router.post(route('transactions.change-status', transaction.id), { closing_type: 'Pending', ...data })
  }

  const handleReviewApproved = () => {
    setBeforeApproveReview(true)
  }

  const handleReviewRequested = () => {
    setBeforeRequestingReview(true)
  }

  const handleReviewPaused = () => {
    setBeforePauseReview(true)
  }

  const showComplianceReviewPausedAlert = () =>
    !transaction.completed_at &&
    !transaction.reviewing &&
    Boolean(transaction.paused_at) && (
      <div className="border-b border-gray-200 bg-white p-4">
        <Alert
          heading={
            <div className="mb-1 text-lg font-bold leading-snug">
              The Compliance Review for this transaction has been <span className="font-bold uppercase text-red-600">PAUSED</span> pending
              your response.
            </div>
          }
          subtext="View the report to review any issues that must be resolved."
          type="danger"
        >
          <Alert.Actions>
            <Button
              type="submit"
              theme="solid"
              colors="bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-offset-1 focus:ring-red-600"
              onClick={() => setComplianceReviewReportOpen(true)}
            >
              View Report
            </Button>
          </Alert.Actions>
        </Alert>
      </div>
    )

  return (
    <ContactProvider>
      <Details columns={2}>
        <Details.Card>
          {dimensions.width >= 768 && (
            <Card
              approvable={approvable}
              onEdit={() => setOpenDrawer('edit')}
              onCancelReview={handleCancelReview}
              onNoteCreating={isCreatingNote}
              onNoteCreated={() => setIsCreatingNote(false)}
              onReviewApproved={handleReviewApproved}
              onReviewRequested={handleReviewRequested}
              onReviewPaused={handleReviewPaused}
            />
          )}
        </Details.Card>

        <Details.LeftColumn>
          {dimensions.width >= 768 ? (
            <ContactConsumer>
              {({ contact, creating, editing, focusedField, settings, searching, onCreating, onSearching, setCreating, setEditing }) => (
                <Fragment>
                  <TransactionContacts
                    group={transaction.type.includes('Fee Only') ? 'Client' : transaction.type}
                    transaction={transaction}
                    onSearching={onSearching}
                  />

                  {transaction.type !== 'Fee Only' && (
                    <TransactionContacts group={transaction.cooperating_party} transaction={transaction} onSearching={onSearching} />
                  )}

                  <SearchContactDialog
                    open={searching}
                    transaction={transaction}
                    settings={settings}
                    onClose={() => onSearching(false)}
                    onCreate={(search) => onCreating(search)}
                  />
                  <TransactionContactEdit
                    fieldToFocus={focusedField}
                    record={contact}
                    open={creating || editing}
                    onClosed={() => {
                      setCreating(false)
                      setEditing(false)
                    }}
                  />
                </Fragment>
              )}
            </ContactConsumer>
          ) : (
            <TransactionMobile transaction={transaction}>
              <Card
                onEdit={() => setOpenDrawer('edit')}
                onCancelReview={handleCancelReview}
                onNoteCreating={isCreatingNote}
                onNoteCreated={() => setIsCreatingNote(false)}
                onReviewRequested={handleReviewRequested}
                onReviewPaused={handleReviewPaused}
              />

              {showComplianceReviewPausedAlert()}
            </TransactionMobile>
          )}
        </Details.LeftColumn>

        <Details.MiddleColumn>
          {dimensions.width >= 768 && (
            <div className="flex h-full flex-col overflow-hidden">
              {myContact && myContact.contact_type === 'Associate' && !myContact?.split_approved && (
                <div className="p-4 pb-0 sm:px-6">
                  <Alert
                    heading="There are changes to your Associate Co-Servicing Agreement for this transaction"
                    subtext="You must review and accept the updated Associate Co-Servicing Agreement in order to close on this transaction."
                    type="warning"
                  >
                    <Alert.Actions>
                      <Button
                        type="submit"
                        theme="solid"
                        colors="bg-orange-600 text-white hover:bg-opacity-75 focus:ring-2 focus:ring-orange-500"
                        onClick={() => router.visit(route('transactions.agreements.index', { transaction: transaction.id }))}
                      >
                        Review Changes
                      </Button>
                    </Alert.Actions>
                  </Alert>
                </div>
              )}

              {transaction.reviewing && (
                <div className="p-4 pb-0 sm:px-6">
                  <Alert
                    heading={
                      transaction.status === 'Compliance'
                        ? 'This transaction is awaiting a Compliance Review for completion.'
                        : 'This transaction is currently being reviewed for Compliance.'
                    }
                    subtext={
                      administrating ? (
                        <div className="space-y-2">
                          <p>
                            {approvable
                              ? 'All files have been approved and payments have been recorded.'
                              : 'The following items must be resolved before this transaction can be approved:'}
                          </p>
                          <ul className="ml-4 list-disc">
                            {declinedFilesCount > 0 && (
                              <li className="font-bold text-red-600">
                                (<span>{declinedFilesCount}</span>) file
                                {declinedFilesCount > 1 ? 's have' : ' has'} been declined
                              </li>
                            )}
                            {notApplicableFilesCount > 0 && (
                              <li className="font-bold text-red-600">
                                (<span>{notApplicableFilesCount}</span>) file
                                {notApplicableFilesCount > 1 ? 's have' : ' has'} been marked NOT APPLICABLE
                              </li>
                            )}
                            {filesAwaitingReviewCount > 0 && (
                              <li>
                                (<span className="font-bold text-black">{filesAwaitingReviewCount}</span>) file
                                {filesAwaitingReviewCount > 1 ? 's are' : ' is'} awaiting review
                              </li>
                            )}
                            {pendingPaymentsCount > 0 && (
                              <li>
                                (<span className="font-bold text-black">{pendingPaymentsCount}</span>) payment
                                {pendingPaymentsCount > 1 ? 's are' : ' is'} pending
                              </li>
                            )}
                          </ul>
                        </div>
                      ) : (
                        `Changes to this transaction are currently restricted.` +
                        (transaction.status === 'Compliance' ? ' You may cancel this request until a review has begun.' : '')
                      )
                    }
                    type="warning"
                  >
                    {administrating && transaction.status === 'Compliance' && (
                      <Alert.Actions>
                        <Button
                          type="submit"
                          theme="solid"
                          colors="bg-orange-600 text-white hover:bg-opacity-75 focus:ring-2 focus:ring-orange-500"
                          onClick={() => handleBeginReview()}
                        >
                          <div className="flex items-center gap-1">
                            <i className={classNames('far fa-file-magnifying-glass w-6 text-center text-xl')}></i>
                            Begin Compliance Review
                          </div>
                        </Button>
                      </Alert.Actions>
                    )}

                    {!administrating && transaction.status === 'Compliance' && (
                      <Alert.Actions>
                        <Button type="submit" theme="outline" onClick={() => setBeforeCancelReview(true)}>
                          Cancel Compliance Review
                        </Button>
                      </Alert.Actions>
                    )}
                  </Alert>
                </div>
              )}

              {showComplianceReviewPausedAlert()}

              <div className="flex items-start justify-between px-8 pt-4">
                <ul
                  role="tablist"
                  className="list-reset mt-2 flex flex-auto flex-wrap justify-start gap-y-4 border-b border-gray-400 border-opacity-20"
                >
                  <Tab active={activeTab === 'summary'} onClick={() => setActiveTab('summary')}>
                    Summary
                  </Tab>
                  <Tab
                    active={activeTab === 'checklist'}
                    onClick={() => setActiveTab('checklist')}
                    disabled={transaction.reviewing && !administrating}
                  >
                    Checklist
                  </Tab>
                  <Tab active={activeTab === 'notes'} onClick={() => setActiveTab('notes')}>
                    Notes
                  </Tab>
                  <Tab active={activeTab === 'files'} onClick={() => setActiveTab('files')}>
                    Files
                  </Tab>
                  <Tab active={activeTab === 'forms'} onClick={() => setActiveTab('forms')} disabled={transaction.restricted}>
                    Forms
                  </Tab>
                  <Tab active={activeTab === 'payments'} onClick={() => setActiveTab('payments')}>
                    Payments
                  </Tab>
                  <Tab active={activeTab === 'activity'} onClick={() => setActiveTab('activity')}>
                    Activity
                  </Tab>
                </ul>
              </div>

              <div className="flex h-full flex-col overflow-hidden">
                <div
                  id="summary"
                  className={classNames('mt-6 flex flex-col overflow-y-auto px-8', {
                    hidden: activeTab !== 'summary',
                  })}
                >
                  <Summary transaction={transaction} />
                </div>

                <div
                  id="checklist"
                  className={classNames('flex h-full flex-col overflow-y-auto', {
                    hidden: activeTab !== 'checklist' || (transaction.reviewing && !administrating),
                  })}
                >
                  <Checklist transaction={transaction} onCancelReview={handleCancelReview} onReviewRequested={handleReviewRequested} />
                </div>

                <div
                  id="notes"
                  className={classNames('mt-6 flex flex-col overflow-y-auto', {
                    hidden: activeTab !== 'notes',
                  })}
                >
                  <div className="mx-8 mb-2 flex items-center justify-between gap-4 pl-3">
                    <div className="flex flex-1 items-center gap-2">
                      <div className="py-1 text-2xl font-medium tracking-wider text-gray-800">Notes</div>
                    </div>

                    <div className="flex h-14 items-center justify-end">
                      <Button theme="solid" onClick={() => setIsCreatingNote(true)}>
                        <i className="fas fa-note-medical mr-2 text-lg"></i>
                        <span className="pr-1">New Note</span>
                      </Button>
                    </div>
                  </div>

                  <Timeline
                    type="note"
                    emptyText={
                      <div className="space-y-1">
                        <i className="far fa-note-sticky text-3xl text-gray-400"></i>
                        <p>No notes have been added to this transaction.</p>
                      </div>
                    }
                  />
                </div>

                <div
                  id="files"
                  className={classNames('mt-6 flex flex-col overflow-y-auto px-8', {
                    hidden: activeTab !== 'files',
                  })}
                >
                  <FileManager />
                </div>

                <div
                  id="forms"
                  className={classNames('mt-6 flex flex-col overflow-y-auto px-8', {
                    hidden: activeTab !== 'forms' || transaction.restricted,
                  })}
                >
                  <Forms />
                </div>

                <div
                  id="payments"
                  className={classNames('mt-6 flex flex-col overflow-y-auto px-8', {
                    hidden: activeTab !== 'payments',
                  })}
                >
                  <Payments />
                </div>

                <div
                  id="activity"
                  className={classNames('mt-6 flex h-full flex-col overflow-hidden', {
                    hidden: activeTab !== 'activity',
                  })}
                >
                  <Timeline />
                </div>
              </div>
            </div>
          )}

          {transaction.editable && (
            <DatesProvider>
              <TransactionEdit open={openDrawer === 'edit'} record={transaction} onClosed={() => setOpenDrawer(null)} />
            </DatesProvider>
          )}
        </Details.MiddleColumn>
      </Details>

      <ApproveReviewDialog open={beforeApproveReview} onCanceled={() => setBeforeApproveReview(false)} />
      <RequestReviewDialog
        open={beforeRequestingReview}
        onCanceled={() => setBeforeRequestingReview(false)}
        onRequested={() => setActiveTab('summary')}
      />
      <CancelReviewDialog
        open={beforeCancelReview}
        onCanceled={() => setBeforeCancelReview(false)}
        onCancelReview={(data) => handleCancelReview(data)}
      />
      <PauseReviewDialog open={beforePauseReview} onCanceled={() => setBeforePauseReview(false)} />
      <PausedComplianceReviewDialog open={complianceReviewReportOpen} onCanceled={() => setComplianceReviewReportOpen(false)} />
    </ContactProvider>
  )
}

TransactionsShow.layout = (page) => (
  <Main title={`${page.props.transaction.name} (${page.props.transaction.type} Transaction)`} children={page} overflowHidden />
)

export default TransactionsShow
